export const descricaoCodigosBoleto = {
  1: "ADESÃO",
  2: "REVISTORIA",
  3: "VISTORIA",
  4: "COMPLEMENTO",
  5: "FECHAMENTO",
  7: "PARTICIPAÇÃO",
  8: "INSTALAÇÃO DE EQUIPAMENTO",
  9: "MANUTENÇÃO DE EQUIPAMENTO",
  10: "MONITORAMENTO",
  11: "BOLETO DE TESTE",
  13: "CANCELAMENTO DE PROTEÇÃO",
  14: "ACORDO FINANCEIRO",
  15: "TRANSFERENCIA",
  16: "ADESÃO DETALHADA",
  17: "MIGRAÇÃO DE SISTEMA",
  18: "REATIVAÇÃO",
  19: "INDENIZAÇÃO",
  20: "INADIMPLÊNCIA",
  21: "PRO-RATA",
  22: "DESINSTALAÇÃO DE EQUIPAMENTO",
  23: "CONTRA-APRESENTAÇÃO",
};

export const codigosBoleto = [
  {
    codigo_tipo_boleto: "1",
    descricao: descricaoCodigosBoleto[1],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "2",
    descricao: descricaoCodigosBoleto[2],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "3",
    descricao: descricaoCodigosBoleto[3],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "4",
    descricao: descricaoCodigosBoleto[4],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "5",
    descricao: descricaoCodigosBoleto[5],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "7",
    descricao: descricaoCodigosBoleto[7],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "8",
    descricao: descricaoCodigosBoleto[8],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "9",
    descricao: descricaoCodigosBoleto[9],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "10",
    descricao: descricaoCodigosBoleto[10],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "11",
    descricao: descricaoCodigosBoleto[11],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "13",
    descricao: descricaoCodigosBoleto[13],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "14",
    descricao: descricaoCodigosBoleto[14],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "15",
    descricao: descricaoCodigosBoleto[15],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "16",
    descricao: descricaoCodigosBoleto[16],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "17",
    descricao: descricaoCodigosBoleto[17],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "18",
    descricao: descricaoCodigosBoleto[18],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "19",
    descricao: descricaoCodigosBoleto[19],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "20",
    descricao: descricaoCodigosBoleto[20],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "21",
    descricao: descricaoCodigosBoleto[21],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "22",
    descricao: descricaoCodigosBoleto[22],
    boleto_adesao: "NÃO",
  },
  {
    codigo_tipo_boleto: "23",
    descricao: descricaoCodigosBoleto[23],
    boleto_adesao: "NÃO",
  },
];

export const tiposBoleto = [
  // {
  //   tipo: "Deposit",
  //   descricao: "Depósito",
  // },
  {
    tipo: "Charge",
    descricao: "Cobrança",
  },
];
