export function documentMask(doc) {
  if (!doc || typeof doc !== "string") return "";

  const filteredDoc = doc.replace(/\D/g, "");

  //Quando o CPF já vier com máscara
  if (filteredDoc.length === 6) {
    return `***.${filteredDoc.substring(0, 3)}.${filteredDoc.substring(
      3,
      6
    )}-**`;
  }

  if (filteredDoc.length <= 11) {
    return `***.${filteredDoc.substring(3, 6)}.${filteredDoc.substring(
      6,
      9
    )}-**`;
  }

  if (filteredDoc.length > 11) {
    return `${filteredDoc.slice(0, 2)}.${filteredDoc.slice(
      2,
      5
    )}.${filteredDoc.slice(5, 8)}/${filteredDoc.slice(
      8,
      12
    )}-${filteredDoc.slice(12)}`;
  }
}
